import React from 'react';

import './App.css';
import { Navbar } from './components/navbar/navbar.js';
import { Hero } from './components/hero/hero.js';
import { Services } from './components/services/services.js';
import { Pricing } from './components/pricing/pricing.js';
import { Location } from './components/location/location.js';
import { Footer } from './components/footer/footer.js';
// import { Vacation } from './components/vacation/vacation.js';
// import { Notification } from './components/notification/notification.js';

export default function App() {
    return (
        <div className="Page w-full">
            {/* <Notification /> */}
            {/* <Vacation /> */}
            
            <Navbar />
            <Hero />

            <Services />
            <Pricing />
            <Location />

            <Footer />
        </div>
    );
}