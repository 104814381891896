import React from 'react';

import './css/pricing.css';


export function Pricing() {
  return (
    <section id="cennik" className="bg-white w-full grid justify-items-center my-4 text-black">
        <div className="w-full text-center pb-8">
            <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900 pb-2">
                Ceník
            </h1>
        </div>

        <div className="max-w-6xl mx-auto px-4 text-center justify-items-center sm:px-6 lg:px-4 pb-12">
            <p className="text-black font-base text-base sm:text-base">
                Pojišťovny v ČR hradí pouze malou část nákladů kvalitní stomatologie, a navíc jen při použití levných (zastaralých) materiálů a postupů – takové však naše ordinace neposkytuje.
                <br/>
                Většina typů ošetření, která vedou k dlouhodobému zachování či kvalitní náhradě Vašeho chrupu bohužel v současné době hrazeny pojišťovnou nejsou nebo je pojišťovny platí jen z části a pacient si musí tato poskytnutá ošetření doplatit.
            </p>
        </div>

        <div className="flex flex-1 flex-col px-4 sm:flex-row sm:px-0">
            
            <div className="">

                <div className="max-w-prose">
                    <div className="overflow-x-auto">
                        <p className="font-bold text-2xl my-4 text-center sm:text-left">
                            Konzervační ošetření
                        </p>
                        <table className="table table-zebra w-full items-center text-base">
                            <tbody>
                                <tr><td>Ošetření zubního kazu – malá výplň</td><td>2000 Kč</td></tr>
                                <tr><td>Ošetření zubního kazu – střední výplň</td><td>2500 Kč</td></tr>
                                <tr><td>Ošetření zubního kazu – velká výplň</td><td>3000 Kč</td></tr>
                                <tr><td>Speciální podložka pod plombu</td><td>500 Kč</td></tr>
                                <tr><td>FRC čep + dostavba zubu (sklovláknový kompozitní kořenový čep, slouží ke zpevnění zubu)</td><td>3000 Kč</td></tr>
                                <tr><td>Provizorní plomba odolnější</td><td>300 Kč</td></tr>
                                <tr><td>Komplexní strojové ošetření kořenových kanálků</td><td>1500 Kč za kanálek</td></tr>
                                <tr><td>Léčebná vložka</td><td>500 Kč</td></tr>
                            </tbody>
                        </table>
                    </div>

                </div>

                <div className="max-w-prose">

                    <div className="overflow-x-auto">

                        <p className="font-bold text-2xl my-4 text-center sm:text-left">
                            Protézy
                        </p>

                        <table className="table table-zebra w-full">
                            <tbody>
                                <tr><td>Oprava vypadlého zubu z protézy</td><td>500 Kč</td></tr>
                                <tr><td>Oprava na modelu – prasklá náhrada</td><td>600 Kč</td></tr>
                                <tr><td>Oprava retenčních prvků</td><td>800 Kč</td></tr>
                                <tr><td>Oprava – rozšíření báze/doplnění zubů do protézy do 2 zubů</td><td>1500 Kč</td></tr>
                            </tbody>
                        </table>
                    </div>
                    
                </div>

                <div className="max-w-prose">

                    <div className="overflow-x-auto">

                        <p className="font-bold text-2xl my-4 text-center sm:text-left">
                            Ostatní
                        </p>

                        <table className="table table-zebra w-full">
                            <tbody>
                                <tr><td>Dlaha bruxismus</td><td>2000 Kč</td></tr>
                                <tr><td>Sutura (šití)</td><td>500 Kč</td></tr>
                                <tr><td>Neomluvená absence</td><td>1000 Kč</td></tr>
                            </tbody>
                        </table>
                    </div>
                    
                </div>

            </div>

            <div className="sm:pl-8 sm:flex sm:flex-col sm:justify-between">

                <div className="max-w-prose">

                    <div className="overflow-x-auto">
                        <p className="font-bold text-2xl my-4 text-center sm:text-left">
                            Korunkové náhrady
                        </p>

                        <table className="table table-zebra w-full">
                            <tbody>
                                <tr><td>Korunka keramická anatomická CAD/CAM (Zirkon)</td><td>6000 Kč</td></tr>
                                <tr><td>Můstek keramický anatomický CAD/CAM (Zirkon)</td><td>18000 Kč</td></tr>
                                <tr><td>Člen v můstku</td><td>6000 Kč</td></tr>
                                <tr><td>Fixní nacementovní korunky</td><td>500 Kč</td></tr>

                                <tr><td>Kořenová Inlay jednokořenová</td><td>2000 Kč</td></tr>
                                <tr><td>Kořenová Inlay dvoukořenová</td><td>3000 Kč</td></tr>
                                <tr><td>Kořenová Inlay tříkořenová</td><td>4000 Kč</td></tr>

                                <tr><td>Imediátní náhrada (poextrakční, dle počtu zubů)</td><td>8000 Kč</td></tr>
                                <tr><td>Částečné snímatelná náhrada ZÁKLADNÍ (dle počtu zubů a materiálu)</td><td>zdravotní pojišťovnou</td></tr>
                                <tr><td>Částečné snímatelná náhrada Deflex (dle počtu zubů a materiálu)</td><td>4500 Kč</td></tr>
                                <tr><td>Celková náhrada horní ZÁKLADNÍ</td><td>hrazeno <wbr />zdravotní pojišťovnou</td></tr>
                                <tr><td>Celková náhrada dolní ZÁKLADNÍ</td><td>hrazeno <wbr />zdravotní pojišťovnou</td></tr>
                            </tbody>
                        </table>
                    </div>
                    
                </div>

            </div>

        </div>

        <div className="max-w-6xl mx-auto px-4 text-center justify-items-center sm:px-6 lg:px-4 py-12">
            <p className="text-black font-base text-base sm:text-base">
                <p>
                Níže uvedené ceny vycházejí ze standardních časů (tzv. minutáže ošetření). Každý pacient a jeho zdravotní stav je vždy individuální. V případě komplikací a větší časové náročnosti ošetření může být plánovaná cena finálně navýšena poměrnou částí minutové kalkulace.
                </p>
                <br />
                <b>
                    Pacient je povinen dodržovat sjednané termíny jednotlivých výkonů v souladu s léčebným plánem, absenci pacienta lze v předstihu omluvit a sjednat náhradní termín. Omluva musí být v takovém případě provedena nejpozději 24 hodin předem.
                </b>
            </p>
        </div>
    </section>
  )
}